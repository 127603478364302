import React from 'react'
import 'twin.macro'

export default function FactDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"Fun fact about SEO",
        sub_heading:"",
        description:"70% to 80%  of users focus exclusively on organic results and ignore paid listings. What’s more, some 28% of those searches convert,   resulting in a purchase.",
        img_src:"https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixqx=03nz4GDdbJ&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80",
       ...finalData
  } 
    return (
        <div tw="bg-white md:pt-8 lg:py-12 w-full">
            <div tw="pb-16 bg-secondary-900 pt-6 md:pt-0 lg:pb-0 lg:z-10 lg:relative">
                <div tw="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                <div tw="relative lg:-my-8">
                    <div aria-hidden="true" tw="absolute inset-x-0 top-0 h-1/2 bg-white hidden"></div>
                    <div tw="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                    <div tw="rounded-xl shadow-xl overflow-hidden lg:h-full">
                        <img tw="object-cover lg:h-full lg:w-full" src={content.img_src} alt="" />
                    </div>
                    </div>
                </div>
                <div tw="mt-8 lg:m-0 lg:col-span-2 lg:pl-8">
                    <div tw="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                        <h3 tw="mt-2 text-base font-light text-white">
                            {content.heading}
                        </h3>
                        <div tw="mt-4 text-2xl font-bold text-white" dangerouslySetInnerHTML={{ __html: content.description }}>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
    )
}
